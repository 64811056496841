<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      incomeStatement: [],
      prevIncomeStatement: [],

      revenues: [],
      expenses: [],

      prevRevenues: [],
      prevexpenses: [],

      netIncome: 0,
      prevNetIncome: 0,

      date: {},
      accountingPeriods: [],

      totalExpenses: 0,
      totalRevenues: 0,

      totalPrevExpenses: 0,
      totalPrevRevenues: 0,

      // branch_name: "",
      filterdPeriod: "during ",
      isMonth: false,
      filterdFrom: "",
      filterdTo: "",

      prevPeriod: {},
    };
  },
  methods: {
    getIncomeStatement() {
      let locale = this.$i18n.locale;
      if (this.date.to) {
        this.isMonth = true;
        this.filterdFrom = this.date.from.split("-").reverse().join("-");
        this.filterdTo = this.date.to.split("-").reverse().join("-");
        this.filterdPeriod = `${
          locale == "ar" ? "من" : "From"
        }  ${this.date.from.split("-").reverse().join("-")} ${
          locale == "ar" ? "الى" : "to"
        } ${this.date.to.split("-").reverse().join("-")}`;
      } else {
        this.isMonth = false;
      }
      if (!this.isMonth) {
        this.accountingPeriods.forEach((period) => {
          if (period.id == this.date.accounting_period_id) {
            this.filterdFrom = period.start_date.split("-").reverse().join("-");
            this.filterdTo = period.end_date.split("-").reverse().join("-");
            this.filterdPeriod = `${
              locale == "ar" ? "من" : "From"
            }  ${period.start_date.split("-").reverse().join("-")} ${
              locale == "ar" ? "الى" : "to"
            } ${period.end_date.split("-").reverse().join("-")}`;
          }
        });
      }
      this.http
        .post("transactions/income-statement", {
          from: this.date.from,
          to: this.date.to,
          accounting_period_id: this.date.accounting_period_id,
        })
        .then((resp) => {
       //   this.date = {};

          this.incomeStatement = resp.data;
          this.prevPeriod = resp.prevPeriod;
          this.totalExpenses = 0;
          this.incomeStatement[1].components.forEach((component) => {
            this.totalExpenses += parseFloat(Math.abs(component.value));
          });
          this.totalRevenues = 0;
          this.incomeStatement[0].components.forEach((component) => {
            this.totalRevenues += parseFloat(component.value);
          });

          this.revenues[0] = this.incomeStatement[0];
          this.expenses[0] = this.incomeStatement[1];
          this.netIncome = this.totalRevenues - this.totalExpenses;

          this.prevIncomeStatement = resp.prevIncomeStetment;
          if (this.prevIncomeStatement[0]?.name) {
            this.totalPrevExpenses = 0;
            this.prevIncomeStatement[1].components.forEach((component) => {
              this.totalPrevExpenses += parseFloat(Math.abs(component.value));
            });
            this.totalPrevRevenues = 0;
            this.prevIncomeStatement[0].components.forEach((component) => {
              this.totalPrevRevenues += parseFloat(component.value);
            });

            this.prevNetIncome =
              this.totalPrevRevenues - this.totalPrevExpenses;
            this.prevRevenues[0] = this.prevIncomeStatement[0];
            this.prevexpenses[0] = this.prevIncomeStatement[1];
          } else {
            this.prevIncomeStatement = [{ components: [] }, { components: [] }];
            this.totalPrevExpenses = 0;
            for (let i = 0; i < resp.data[1].components.length; i++) {
              this.prevIncomeStatement[1]?.components.push({ value: 0 });
            }
            this.totalPrevRevenues = 0;
            for (let i = 0; i < resp.data[0].components.length; i++) {
              this.prevIncomeStatement[0]?.components.push({ value: 0 });
            }

            this.prevNetIncome =
              this.totalPrevRevenues - this.totalPrevExpenses;
            this.prevRevenues[0] = this.prevIncomeStatement[0];
            this.prevexpenses[0] = this.prevIncomeStatement[1];
          }
          console.log({ prevIncomeStatement: this.prevIncomeStatement });
        });
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
  },
  created() {
    // let current_user = JSON.parse(localStorage.currentUser);
    // this.branch_name = current_user.branch_name;
    this.getAccountingPeriods();
    // this.getIncomeStatement();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('menu.menuitems.accounting.subItems.income-statement')"
    />

    <form @submit.prevent="getIncomeStatement()">
      <div class="row mb-4">
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.period")
          }}</label>
          <select
            v-model="date.accounting_period_id"
            type="date"
            class="form-control"
            id="fromJobTitle"
          >
            <option
              :value="period.id"
              v-for="period in accountingPeriods"
              :key="period"
            >
              {{
                period?.start_date +
                ` ${this.$i18n.locale == "ar" ? "الى" : "To"} ` +
                period?.end_date
              }}
            </option>
          </select>
        </div>
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.from")
          }}</label>
          <input
            v-model="date.from"
            type="date"
            class="form-control"
            id="fromJobTitle"
          />
        </div>
        <div class="col">
          <label class="inline" for="toJobTitle">{{ $t("report.to") }}</label>
          <input
            v-model="date.to"
            type="date"
            class="form-control fromto"
            id="toJobTitle"
          />
        </div>
        <div class="col-auto" style="top: 16px">
          <button type="submit" class="btn btn-primary">
            {{ $t("popups.search") }}
          </button>
        </div>

        <div class="col"></div>
      </div>
    </form>
    <div class="card" v-if="revenues.length > 0">
      <div class="card-body">
        <ReportsHeader
          v-if="revenues.length > 0"
          :filterdPeriod="`${
            $i18n.locale == 'ar'
              ? 'من ' + filterdFrom + ' الى ' + filterdTo + '\n'
              : 'From ' + filterdFrom + ' To ' + filterdTo
          }`"
          class="my-4"
          :reportName="$t('reports.income_statement')"
        />
        <div class="table-responsive">
          <table class="table table-centered table-nowrap align-middle">
            <thead>
              <th></th>
              <th>
                {{
                  prevPeriod
                    ? prevPeriod?.end_date.split("-").reverse().join("-")
                    : "-"
                }}
              </th>
              <th></th>
              <th>{{ filterdTo }}</th>
            </thead>
            <tbody>
              <tr v-for="income in revenues" :key="income">
                <th>
                  {{ $i18n.locale == "ar" ? income?.name : income?.name_en }}
                </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <div
                    class="t-cell"
                    style="margin-inline-start: 20px"
                    v-for="component in revenues[0]?.components"
                    :key="component"
                  >
                    {{
                      $i18n.locale == "ar"
                        ? component?.name
                        : component?.name_en
                    }}
                  </div>
                  <div class="t-cell" style="margin-inline-start: 20px">
                    {{ $t("reports.total_revenues") }}
                  </div>
                </td>
                <td>
                  <div
                    class="t-cell"
                    v-for="(component, index) in revenues[0]?.components"
                    :key="component"
                  >
                    {{
                      Math.abs(
                        prevIncomeStatement[0]?.components[index]?.value
                      ).toLocaleString()
                    }}
                  </div>
                  <div class="total-style t-cell">
                    {{ parseFloat(totalPrevRevenues).toLocaleString() }}
                  </div>
                </td>
                <td></td>
                <td>
                  <div
                    class="t-cell"
                    v-for="component in revenues[0]?.components"
                    :key="component"
                  >
                    {{ Math.abs(component?.value).toLocaleString() }}
                  </div>
                  <div class="total-style t-cell">
                    {{ parseFloat(totalRevenues).toLocaleString() }}
                  </div>
                </td>
              </tr>
              <tr v-for="expense in expenses" :key="expense">
                <th>
                  {{ $i18n.locale == "ar" ? expense?.name : expense?.name_en }}
                </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <div
                    class="t-cell"
                    style="margin-inline-start: 20px"
                    v-for="component in expenses[0]?.components"
                    :key="component"
                  >
                    {{
                      $i18n.locale == "ar"
                        ? component?.name
                        : component?.name_en
                    }}
                  </div>
                  <div class="t-cell" style="margin-inline-start: 20px">
                    {{ $t("reports.total_expenses") }}
                  </div>
                </td>
                <td>
                  <div
                    class="t-cell"
                    v-for="(component, index) in expenses[0]?.components"
                    :key="component"
                  >
                    {{
                      Math.abs(
                        prevIncomeStatement[1]?.components[index]?.value
                      ).toLocaleString()
                    }}
                  </div>
                  <div class="total-style t-cell">
                    {{ parseFloat(totalPrevExpenses).toLocaleString() }}
                  </div>
                </td>
                <td></td>
                <td>
                  <div
                    class="t-cell"
                    v-for="component in expenses[0]?.components"
                    :key="component"
                  >
                    {{ Math.abs(component?.value).toLocaleString() }}
                  </div>
                  <div class="total-style t-cell">
                    {{ parseFloat(totalExpenses).toLocaleString() }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  {{ $i18n.locale == "ar" ? "الدخل الصافي" :"Net Income" }}
                </th>
                <th>{{0 > prevNetIncome ? "(":""}}{{Math.abs(prevNetIncome).toLocaleString()}}{{0 > prevNetIncome ? ")":""}}</th>
                <th></th>
                <th>{{0 > netIncome ? "(":""}}{{Math.abs(netIncome).toLocaleString()}}{{0 > netIncome ? ")":""}}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.t-cell {
  padding: 0.8rem;
}
</style>
